import { globalSlice } from './Global/Global.slice';
import { helloSlice } from './hello/hello.slice';
import { routesSlice } from './Routes/Routes.slice';
import { toursSlice } from './Tours/Tours.slice';

export const rootReducer = {
  HelloWorld: helloSlice.reducer,
  Routes: routesSlice.reducer,
  Global: globalSlice.reducer,
  Tour: toursSlice.reducer,
};
