import { useState } from 'react';
import Terms from './Terms';
import axios from 'axios';
import { Formik, Form } from 'formik';
import InputField from './InputField';
import Button from './Button';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { swalResponse } from './swalResponse';
import $ from 'jquery';

const CDC = () => {
  const [termsActive, setTermsActive] = useState(false);

  async function getUsersIp() {
    try {
      let { ip } = await $.get('https://api.ipify.org/?format=json');

      return ip;
    } catch (error) {
      return '0.0.0.0';
    }
  }

  return (
    <div className="flex flex-col justify-center items-center px-[4vh] text-white lg:min-h-screen font-sans">
      <div className="mb-[3vh] flex flex-col text-center">
        <span className="mb-[10px] font-bold text-[20px] lg:whitespace-nowrap">
          Join the Official OMB Peezy Newsletter
        </span>
        <span className="text-[14px] lg:whitespace-nowrap">
          Sign up for news about new music, videos, tour dates and exclusive offers.
        </span>
      </div>
      <div className="w-full">
        <Formik
          initialValues={{
            email: '',
            name: 'OMBPeezy',
            newsletterId: '20001438',
            ref_url: 'https://ombpeezymusic.com',
            dataSource: 'OMBPeezy_MainNewsletter_MainSignup_Website',
            artist_mktg_consent: 'yes',
            artist_mktg_consent_txt: 'yes',
            privacy_policy_url: 'https://privacy.wmg.com/3EE/privacy-policy',
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const ip_address = await getUsersIp();

            // SUBSCRIBE TO WMG CDC API
            const { data } = await axios.post(
              `https://signup.wmg.com/register?name=${values.name}&email=${values.email}&newsletterId=${values.newsletterId}&ref_url=${values.ref_url}&dataSource=${values.dataSource}&ip_address=${ip_address}&artist_mktg_consent=${values.artist_mktg_consent}&artist_mktg_consent_txt=${values.artist_mktg_consent_txt}&privacy_policy_url=${values.privacy_policy_url}&cd=true`,
            );

            if (data !== 'SUCCESS') {
              swalResponse({
                icon: 'error',
                text: 'Sorry, something is wrong with WMG CDC',
              });
              setSubmitting(false);
              return;
            }

            swalResponse({
              text: 'Thank you for signing up!',
              icon: 'success',
            });
            resetForm();
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="my-2 flex flex-col justify-center items-center w-full">
              <div className="mb-3 w-full">
                <InputField placeholder="email" name="email" />
              </div>
              <div>
                <Button type="submit" isSubmitting={isSubmitting}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* <Terms visible={true} set={setTermsActive} /> */}
      <div className="mt-[3vh] text-center">
        <button
          className="text-center uppercase text-[12px]"
          onClick={() => {
            setTermsActive(!termsActive);
          }}
        >
          terms
          {termsActive ? (
            <FontAwesomeIcon icon={faChevronUp} className="ml-1" />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
          )}
        </button>
      </div>
      <div className="mt-2 w-full lg:max-w-[400px] text-center">
        <Terms visible={termsActive} set={setTermsActive} />
      </div>
    </div>
  );
};

export default CDC;
