import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ToursTypes } from "./types";

export const toursAdapter = createEntityAdapter<ToursTypes>({
  selectId: (item) => item.index,
});

const initialState = {
  items: toursAdapter.getInitialState(),
};

export const toursSlice = createSlice({
  name: "tours",
  initialState,
  reducers: {
    setToursItems: (state, { payload }: PayloadAction<ToursTypes[]>) => {
      toursAdapter.addMany(state.items, payload);
    },
  },
});

export const { setToursItems } = toursSlice.actions;
