import { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import './styles/main.css';
import './App.css';
import Background from './components/Background/Background';
import useTourController from './app/Hooks/useTourController';
import useToursService from './app/Hooks/useToursService';

function App() {
  return (
    <div className="overflow-hidden">
      <Suspense fallback={null}>
        <Router>
          <Routes>
            <Route path="*" element={<AppRoutes />} />
          </Routes>
        </Router>
      </Suspense>
      <Background />
    </div>
  );
}

export default App;
