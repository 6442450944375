import axios from 'axios';
import { ToursTypes } from '../../features/Tours/types';

export default class TourController {
  async getTourItems() {
    const id = '1I-KT_lCyI0-sgQUyU3qCBm4d4y6O3b50DPowOqmfFic';
    const {
      data: { values },
    } = await axios.get(
      `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/Sheet1?key=${process.env.REACT_APP_GOOGLESHEETS_API}`,
    );

    const formattedData: ToursTypes[] = [];

    for (let i = 1; i <= values.length - 1; i++) {
      const item = values[i]; // []

      const parsedObj = {
        index: 0,
        date: '',
        venue: '',
        address: '',
        tickets: '',
        vip: '',
        past: false,

        inCSV: function (str: string[], i: number) {
          this.index = i;
          this.date = str[0];
          this.address = str[1];
          this.venue = str[2];
          this.tickets = str[3];
          this.vip = str[4];
          this.past = isTourHappened(str[0]);
        },
      };

      // parsed from CSV to objecct
      parsedObj.inCSV(item, i);
      // push transformed object to formatted data
      formattedData.push(parsedObj);
    }

    return formattedData;
  }
}

function isTourHappened(date: string) {
  const dateObj = new Date(
    new Date().toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' }),
  );

  // get date - 3 to show previous date
  const presentTime = dateObj.setDate(dateObj.getDate());

  const targetTime = new Date(date).getTime();

  return presentTime > targetTime;
}
