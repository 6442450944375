import { motion } from 'framer-motion';
import { ListsSlideUpStagger, ListsVariants } from 'src/components/FramerMotion/Lists';
import Li from 'src/components/Li/Li';
import { MenuItemsTypes, OMBDataTypes } from './Data';
import CDC from 'src/components/CDC/CDC';

interface MenuProps {
  menuItems: MenuItemsTypes[];
  videos: OMBDataTypes[];
}

export default function Menu({ menuItems, videos }: MenuProps) {
  const MapItems = menuItems.map((item, idx) => {
    return (
      <motion.div
        key={idx}
        className="tv z-20 flex flex-row justify-center items-center shadow-lg"
        variants={ListsSlideUpStagger}
      >
        <div className="z-20 px-2 py-1 bg-primary rounded-sm shadow-md">
          {item.to ? (
            <Li to={item.to}>{item.name}</Li>
          ) : (
            <a
              href={item.href}
              target="_blank"
              rel="noreferrer"
              className="link font-display block text-lg md:text-2xl lg:3xl uppercase bg-primary text-white"
            >
              {item.name}
            </a>
          )}
        </div>
        <video
          loop
          muted
          autoPlay
          playsInline
          className="absolute top-0 w-full h-full object-cover"
        >
          <source src={videos[idx].webm} type="video/webm" />
          <source src={videos[idx].mp4} type="video/mp4" />
          <img src={videos[idx].gif} alt="obm peezy" />
        </video>
      </motion.div>
    );
  });

  return (
    <>
      <motion.div
        className="tv-container grid grid-cols-2 gap-5 lg:gap-10 lg:grid-cols-3"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={ListsVariants}
      >
        {MapItems}
      </motion.div>
    </>
  );
}
