import { motion } from 'framer-motion';
import { ZoomOut } from '../../components/FramerMotion/Zoom';
import Heading from '../../components/Heading/Heading';

import { Data, MenuItems } from './Data';
import Menu from './Menu';
import Sidebar from './Sidebar';
import CDC from '../../components/CDC/CDC';

export default function Home() {
  return (
    <motion.div className="relative min-h-screen w-full h-full" {...ZoomOut}>
      <div className="z-10 flex flex-col items-center justify-center min-h-screen">
        <Heading level={1}>OMB Peezy</Heading>
        <Menu videos={Data} menuItems={MenuItems} />
      </div>
      <div className="relative z-10">
        <CDC />
      </div>
      <Sidebar />
    </motion.div>
  );
}
