import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../Store";
import { toursAdapter } from "./Tours.slice";

export const toursSelector = toursAdapter.getSelectors(
  (state: RootState) => state.Tour.items
);

// get all tours
export const getAllTours = createSelector(
  toursSelector.selectAll,
  (item) => item
);
