import { motion } from 'framer-motion';
import { SlideUp } from '../FramerMotion/SlideUp';

interface HeadingProps {
  level: number;
  children: any;
}

export default function Heading({ level, children }: HeadingProps) {
  switch (level) {
    case 1:
      return (
        <motion.h1
          className="heading-1 uppercase whitespace-nowrap text-5xl sm:text-6xl md:text-7xl text-white z-10"
          {...SlideUp}
        >
          {children}
        </motion.h1>
      );
    default:
      return null;
  }
}
