import { motion } from 'framer-motion';
import { useState } from 'react';
import Back from 'src/components/Back/Back';
import { ZoomIn } from 'src/components/FramerMotion/Zoom';
import Heading from 'src/components/Heading/Heading';
import VideoContainer from 'src/components/VideoContainer/VideoContainer';
import VideoContainer2 from 'src/components/VideoContainer/VideoContainer2';
import { RoutePattern } from 'src/routes/RoutePattern';
import { Data, Data2 } from './Data';

export default function Music() {
  const [click, setClick] = useState<boolean>(false);

  return (
    <motion.div
      className="w-full h-full min-h-screen relative flex flex-col justify-center items-center py-[4vh]"
      {...ZoomIn}
    >
      <Back to={RoutePattern.Home} />
      <Heading level={1}>OMB PEEZY</Heading>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <VideoContainer2 fillers={Data} />
        {click && <VideoContainer2 fillers={Data2} />}
      </div>
      <div className="">
        <button
          onClick={() => setClick(!click)}
          className="mx-auto outline-none font-wide block text-center my-10 text-white italic uppercase text-lg opacity-80 hover:underline hover:opacity-100"
        >
          {click ? 'view less' : 'view more'}
        </button>
      </div>
    </motion.div>
  );
}
