import Gif1 from './images/1.gif';
import Gif2 from './images/2.gif';
import Gif3 from './images/3.gif';
import Image1 from './images/1.jpg';
import Image2 from './images/2.jpg';
import Image3 from './images/3.jpg';
import Image4 from './images/4.jpg';
import Image5 from './images/5.jpeg';
import Image6 from './images/6.jpeg';
import Image7 from './images/7.png';
import Image8 from './images/8.png';
import LeParis from './images/leparis.jpg';

export const Data = [
  {
    gif: Gif1,
    mov: 'https://linkstorage.linkfire.com/medialinks/images/2f68ecc8-6bd6-4491-b0ee-91815bc235ea/artwork-440x440.jpg',
    to: 'https://ombpeezy.lnk.to/StillTooDeep',
  },
  {
    gif: Gif2,
    mov: 'https://linkstorage.linkfire.com/medialinks/images/5ca695f0-dc8c-466f-a0fc-9cab91adf70e/artwork-440x440.jpg',
    to: 'https://ombpeezy.lnk.to/Bulletproof',
  },
  {
    gif: Gif3,
    mov: 'https://linkstorage.linkfire.com/medialinks/images/d40ca8bb-dbc6-4ef3-97f8-c8e3f4cc1c1b/artwork-440x440.jpg',
    to: 'https://ombpeezy.lnk.to/HallwaystoHighways',
  },
  {
    gif: Gif2,
    mov: 'https://linkstorage.linkfire.com/medialinks/images/dbe07135-11d8-44df-82c8-1d4504e80eb9/artwork-440x440.jpg',
    to: 'https://ombpeezy.lnk.to/LeParis',
  },
  {
    gif: Gif3,
    mov: 'https://linkstorage.linkfire.com/medialinks/images/a6875931-8d9b-4ec4-8e7d-13c6f68ff5f4/artwork-440x440.jpg',
    to: 'https://ombpeezy.lnk.to/thestreets',
  },
  {
    gif: Gif1,
    mov: 'https://linkstorage.linkfire.com/medialinks/images/0925bb59-8c5a-4646-a738-8f844cb9a5a2/artwork-440x440.jpg',
    to: 'https://ombpeezy.lnk.to/neverchange',
  },
  // {
  //   gif: Gif2,
  //   mov: 'https://cloudinary-cdn.ffm.to/s--Edkt4FRO--/f_webp/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F380aa84bde8aa5acffdc4ca41635f5ff.jpeg',
  //   to: 'https://ombpeezy.lnk.to/thinkyouready',
  // },
  // {
  //   gif: Gif1,
  //   mov: 'https://cloudinary-cdn.ffm.to/s--DYwkubXO--/f_webp/https%3A%2F%2Fimagestore.ffm.to%2Flink%2Fc7a2e17d23931108d2e9f19faa40ea73.jpeg',
  //   to: 'https://ombpeezy.ffm.to/misguided',
  // },
  // {
  //   gif: Gif2,
  //   mov: 'https://cloudinary-cdn.ffm.to/s--DMXgSS3u--/f_webp/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F012752cb4853e0ac5a067a11343f4de5.jpeg',
  //   to: 'https://ombpeezy.ffm.to/mufasa',
  // },
  // {
  //   gif: Gif1,
  //   mov: Image8,
  //   to: ' https://ombpeezy.ffm.to/mission',
  // },
];

export const Data2 = [
  {
    gif: Gif2,
    mov: 'https://cloudinary-cdn.ffm.to/s--Edkt4FRO--/f_webp/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F380aa84bde8aa5acffdc4ca41635f5ff.jpeg',
    to: 'https://ombpeezy.lnk.to/thinkyouready',
  },
  {
    gif: Gif1,
    mov: 'https://cloudinary-cdn.ffm.to/s--DYwkubXO--/f_webp/https%3A%2F%2Fimagestore.ffm.to%2Flink%2Fc7a2e17d23931108d2e9f19faa40ea73.jpeg',
    to: 'https://ombpeezy.ffm.to/misguided',
  },
  {
    gif: Gif3,
    mov: 'https://cloudinary-cdn.ffm.to/s--DMXgSS3u--/f_webp/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F012752cb4853e0ac5a067a11343f4de5.jpeg',
    to: 'https://ombpeezy.ffm.to/mufasa',
  },
  {
    gif: Gif2,
    mov: Image8,
    to: ' https://ombpeezy.ffm.to/mission',
  },
  {
    gif: Gif3,
    mov: Image7,
    to: 'https://ombpeezy.ffm.to/itm2',
  },
  {
    gif: Gif1,
    mov: Image2,
    to: 'https://ombpeezy.ffm.to/dopeboy',
  },
  {
    gif: Gif3,
    mov: Image3,
    to: 'https://ombpeezy.ffm.to/bighomie-rmx',
  },
  {
    gif: Gif1,
    mov: Image6,
    to: 'https://ffm.to/loyaltyoverlove',
  },
  {
    gif: Gif2,
    mov: Image5,
    to: 'https://ombpeezy.ffm.to/ptts',
  },
  {
    gif: Gif2,
    mov: Image4,
    to: 'https://ombpeezy.ffm.to/inthemeantime',
  },
  {
    gif: Gif3,
    mov: Image1,
    to: 'https://ombpeezy.ffm.to/tdft',
  },
  {
    gif: Gif1,
    mov: 'https://res.cloudinary.com/feature-fm/image/fetch/s--NslY1cXi--/f_auto/https%3A%2F%2Fimagestore.ffm.to%2Flink%2F2f5447f44b5ceedf31e94bba3686e7c8.jpeg',
    to: 'https://ombpeezy.ffm.to/650_',
  },
];
