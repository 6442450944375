import Gif1 from './images/1.gif';
import Gif2 from './images/2.gif';
import Gif3 from './images/3.gif';

export const Data = [
  {
    gif: Gif1,
    mov: Gif1,
    to: '!#',
  },
  {
    gif: Gif2,
    mov: Gif2,
    to: '!#',
  },
  {
    gif: Gif3,
    mov: Gif3,
    to: '!#',
  },
  {
    gif: Gif2,
    mov: Gif2,
    to: '!#',
  },
  {
    gif: Gif3,
    mov: Gif3,
    to: '!#',
  },
  {
    gif: Gif1,
    mov: Gif1,
    to: '!#',
  },
];
