import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Fade } from '../FramerMotion/Fade';

interface BackProps {
  to: string;
}

export default function Back({ to }: BackProps) {
  return (
    <motion.div {...Fade}>
      <Link to={to} className="font-wide text-white fixed top-5 right-8 hover:text-secondary">
        x
      </Link>
    </motion.div>
  );
}
