import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import { InputHTMLAttributes } from 'react';

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
  placeholder?: string;
};

const InputField = ({ label, placeholder, ...props }: InputFieldProps) => {
  const [field, { error }] = useField(props);
  return (
    <div className="flex flex-col w-full m-auto md:w-[30rem]">
      {label && <label className="capitalize">{label}</label>}
      <input
        className="bg-transparent border border-primary text-[12px] text-white text-center block h-[2.5rem] px-4 placeholder:text-center placeholder:uppercase placeholder:text-white outline-none focus:bg-transparent"
        style={{ border: !!error ? '1px solid rgb(239 68 68)' : '' }}
        id={field.name}
        placeholder={placeholder}
        {...field}
        {...props}
      />
      {!!error && (
        <span className="text-red-500 px-2 mt-2 text-sm">
          <FontAwesomeIcon icon={faWarning} className="mr-2" />
          {error}
        </span>
      )}
    </div>
  );
};

export default InputField;
