import { Link, LinkProps } from 'react-router-dom';

interface LiProps extends LinkProps {
  children: any;
}

export default function Li({ to, children }: LiProps) {
  return (
    <Link
      className="link font-display block text-lg md:text-2xl lg:3xl uppercase bg-primary text-white"
      to={to}
    >
      {children}
    </Link>
  );
}
