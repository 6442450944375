import { motion } from 'framer-motion';
import { ListsSlideUpStagger, ListsVariants } from '../FramerMotion/Lists';
import gif from '../../assets/images/tv_filler.gif';
import styled from 'styled-components';

const VideoItem = styled(motion.div)`
  margin: 0 auto;
  width: 100%;
  /* width: 200px; */
  height: 120px;

  @media (min-width: 768px) {
    /* width: 300px; */
    height: 180px;
  }
  @media (min-width: 1024px) {
    /* width: 250px; */
    height: 150px;
  }

  @media (min-width: 1280px) {
    /* width: 280px; */
    height: 200px;
  }

  @media (min-width: 1600px) {
    width: 400px;
    height: 250px;
  }
`;

interface MenuProps {
  fillers: { gif?: string; mov: string; to: string }[];
}

export default function VideoContainer2({ fillers }: MenuProps) {
  const MapItems = fillers.map((item, idx) => {
    return (
      <VideoItem key={idx} className="z-20 shadow-lg" variants={ListsSlideUpStagger}>
        <a
          target="_blank"
          href={item.to}
          rel="noreferrer"
          className="tv flex flex-row justify-center items-center"
        >
          <div className="absolute top-0 left-0 h-full w-full shadow-lg">
            <img className="object-cover w-full h-full" src={item.mov} alt="omb peezy" />
          </div>
          <div className="absolute z-20 opacity-50 w-full">
            <img className="w-full object-cover" src={item.gif ? item.gif : gif} alt="omb peezy" />
          </div>
        </a>
      </VideoItem>
    );
  });

  return (
    <>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        className="w-full h-full grid grid-cols-2 mb-10 px-5 md:px-10 lg:px-36 xl:px-0 gap-5 | lg:gap-10 lg:grid-cols-3 xl:w-3/4"
        variants={ListsVariants}
      >
        {MapItems}
      </motion.div>
    </>
  );
}
