import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import Back from 'src/components/Back/Back';
import { ZoomIn } from 'src/components/FramerMotion/Zoom';
import Heading from 'src/components/Heading/Heading';
import { shopify } from 'src/components/Shopify/shopify';
import { RoutePattern } from 'src/routes/RoutePattern';

export default function Shop() {
  useEffect(() => {
    const w = window as any;
    w.showProducts();
  }, []);

  return (
    <motion.div
      className="w-full h-full min-h-screen flex flex-col justify-center items-center"
      {...ZoomIn}
    >
      <Back to={RoutePattern.Home} />
      <Heading level={1}>OMB Peezy</Heading>
      <div className="flex flex-col justify-center items-center w-full h-full py-10">
        <div id="collection-component-1614912618353" className="px-10 lg:px-24 xl:px-40"></div>
      </div>
    </motion.div>
  );
}
