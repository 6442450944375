import { MotionProps } from 'framer-motion';
import { TransitionPowerOne } from './Transitions';

export const Fade: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    ...TransitionPowerOne,
    duration: 1.5,
  },
};
