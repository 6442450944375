import { useSelector } from 'react-redux';
import { getAllTours } from '../../features/Tours/Tours.selector';
import TourComponent from '../../components/Tour/Tour';
import Back from 'src/components/Back/Back';
import { RoutePattern } from 'src/routes/RoutePattern';
import { ZoomIn } from 'src/components/FramerMotion/Zoom';
import { motion } from 'framer-motion';
import useTourController from '../Hooks/useTourController';
import useToursService from '../Hooks/useToursService';
import { useEffect } from 'react';
import { SlideUp } from 'src/components/FramerMotion/SlideUp';
import TOUR_IMG from '../../assets/images/1.png';
import TOUR_IMG_MOBILE from '../../assets/images/2.png';
import styled from 'styled-components';

const Img = styled.img`
  width: 100vw;
  object-fit: contain;
  /* position: absolute; */
  top: 0;
  left: 0;

  /* height: 90vh; */
`;

const Tour = () => {
  const { getTourItems } = useTourController();

  const { setToursItems } = useToursService();
  const data = useSelector(getAllTours);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      const d = await getTourItems();
      setToursItems(d);
    });

    return () => clearTimeout(timeout);
  }, []);

  return (
    <motion.div className="w-full h-full min-h-screen relative flex flex-col bg-black" {...ZoomIn}>
      <Back to={RoutePattern.Home} />
      {/* <motion.h1
        className="heading-1 uppercase whitespace-nowrap text-5xl sm:text-6xl md:text-7xl text-white text-center z-10"
        {...SlideUp}
      >
        OMB PEEZY
      </motion.h1> */}
      <Img src={TOUR_IMG} alt="event" className="hidden md:block" />
      <Img src={TOUR_IMG_MOBILE} alt="event" className="block md:hidden" />

      <div className="w-full flex justify-center items-center flex-col py-10 md:py-24">
        <h2 className="text-white text-5xl text-center m-auto">TOUR</h2>
        <div className="mt-10">
          <TourComponent data={data} />
        </div>
      </div>
    </motion.div>
  );
};
export default Tour;
