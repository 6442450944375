import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import { RoutePattern } from './RoutePattern';
import { Home, Music, PageNotFound, Videos, Shop, Contact, Tour } from '../app/index';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path={RoutePattern.Home} element={<Home />} />
        <Route path={RoutePattern.Shop} element={<Shop />} />
        <Route path={RoutePattern.Music} element={<Music />} />
        <Route path={RoutePattern.Videos} element={<Videos />} />
        <Route path={RoutePattern.Tours} element={<Tour />} />
        <Route path={RoutePattern.Contact} element={<Contact />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
