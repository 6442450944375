import BGImage from '../../assets/images/BG.png';
import BGImageMobile from '../../assets/images/BG-mobile.png';
import BGTexture from '../../assets/images/texture.png';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ZoomOut } from '../FramerMotion/Zoom';
import styled from 'styled-components';

const BGContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -121231;
`;

export default function Background() {
  const [width, setWidth] = useState<number>(600);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', function () {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <BGContainer {...ZoomOut} className="fixed">
      {/* <img
        className="min-h-screen w-full h-full object-cover absolute top-0 left-0 behind-1"
        src={BGTexture}
        alt="texture layer"
      /> */}
      <img
        className="min-h-screen w-full h-full object-top object-cover absolute top-0 left-0 behind-2"
        src={width >= 600 ? BGImage : BGImageMobile}
        alt="background"
        style={{ zIndex: -1 }}
      />
    </BGContainer>
  );
}
