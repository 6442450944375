import { motion } from 'framer-motion';
import Back from 'src/components/Back/Back';
import { ZoomIn } from 'src/components/FramerMotion/Zoom';
import Heading from 'src/components/Heading/Heading';
import { RoutePattern } from 'src/routes/RoutePattern';
import styled from 'styled-components';
import { Data } from './Data';

const Video = styled.div`
  height: 250px;
  width: 400px;

  @media (min-width: 768px) {
    width: 450px;
    height: 300px;
  }
`;

export default function Contact() {
  return (
    <motion.div
      className="w-full h-full min-h-screen relative flex flex-col justify-center items-center"
      {...ZoomIn}
    >
      <Back to={RoutePattern.Home} />
      <Heading level={1}>OMB Peezy</Heading>
      <Video className="mt-10">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://my.community.com/ombpeezy"
          className="tv flex flex-col text-center justify-center items-center font-body"
        >
          <h2 className="z-50 text-black text-7xl block w-full">+1(251) 428-1453</h2>
          <h2 className="z-50 text-black text-7xl uppercase block w-full">text me</h2>
          <div className="absolute top-0 left-0 h-full w-full shadow-lg">
            <img className="object-cover w-full h-full" src={Data[4].mov} alt="omb peezy" />
          </div>
          <div className="absolute z-20 opacity-50 w-full">
            <img className="w-full object-cover" src={Data[4].gif} alt="omb peezy" />
          </div>
        </a>
      </Video>
    </motion.div>
  );
}
