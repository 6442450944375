import { MotionProps } from 'framer-motion';

export const SlideUp: MotionProps = {
  initial: {
    marginTop: 280,
    opacity: 0,
  },
  animate: {
    marginTop: 0,
    opacity: 1,
  },
  exit: {
    marginTop: 0,
  },
};
