import Gif1 from '../../assets/images/tv_filler.gif';
import mov7 from '../Music/images/8.png';
import IMG_1 from './videos/9.jpg';

export const Data = [
  // {
  //   gif: Gif1,
  //   mov: IMG_1,
  //   to: 'https://www.youtube.com/watch?v=tWUkxHCYr84',
  // },
  // {
  //   gif: Gif1,
  //   mov: mov7,
  //   to: 'https://www.youtube.com/watch?v=iMFsK2XBLK8',
  // },
];
