import axios from 'axios';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import Back from 'src/components/Back/Back';
import { ZoomIn } from 'src/components/FramerMotion/Zoom';
import Heading from 'src/components/Heading/Heading';
import VideoContainer2 from 'src/components/VideoContainer/VideoContainer2';
import { RoutePattern } from 'src/routes/RoutePattern';
import { Data } from './Data';

export default function Videos() {
  const [items, set] = useState<any>([]);
  const [items2, set2] = useState<any>([]);
  const [click, setClick] = useState<boolean>(false);

  useEffect(() => {
    const playlist_id = 'PL8Ucth1S6j2N8GCG7yqQyM1Y_wB8X1SSK';
    const youtube_api = 'AIzaSyCapJ5cgPo02rxlhEeHSEJwgV-nQRKnucA';
    const video_list = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlist_id}&fields=items(snippet(resourceId%2FvideoId%2Cthumbnails%2Fmedium%2Furl%2Ctitle))&key=${youtube_api}`;

    getVideos(video_list);
  }, []);

  async function getVideos(target: string) {
    const { data } = await axios.get(target);
    const newData: any = [];
    data.items.map((item: any) => {
      const url = item.snippet.thumbnails.medium;
      if (!url) return;

      return newData.push({
        to: `https://www.youtube.com/watch?v=${item.snippet.resourceId.videoId}&list=PL8Ucth1S6j2N8GCG7yqQyM1Y_wB8X1SSK&index=2&ab_channel=OMBPeezy`,
        mov: url.url,
      });
    });

    if (newData.length >= 6) {
      const localData = Data[0]; // local data
      const first = [newData[0], newData[1], newData[2], newData[3], newData[4], newData[5]];
      const second = newData.splice(first.length);
      set(first);
      set2(second);
    }
  }

  function handleClick() {
    setClick(!click);
  }

  // tslint:disable-next-line: curly
  if (!items) return <></>;

  return (
    <motion.div
      className="w-full h-full min-h-screen relative flex flex-col justify-center items-center"
      {...ZoomIn}
    >
      <Back to={RoutePattern.Home} />
      <Heading level={1}>OMB Peezy</Heading>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <VideoContainer2 fillers={items} />
        {click && <VideoContainer2 fillers={items2} />}
      </div>
      <button
        onClick={handleClick}
        className="mx-auto outline-none font-wide block text-center my-10 text-white italic uppercase text-lg opacity-80 hover:underline hover:opacity-100"
      >
        {click ? 'view less' : 'view more'}
      </button>
    </motion.div>
  );
}
