import {
  faSoundcloud,
  faSpotify,
  faTiktok,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { Fade } from 'src/components/FramerMotion/Fade';

export default function Sidebar() {
  return (
    <motion.div className="sidebar z-0" {...Fade}>
      <ul className="flex flex-row md:flex-col md:absolute md:right-0">
        <li className="sidebar-item z-20 text-white transition-all">
          <a target="_blank" href="https://www.instagram.com/omb_peezy/">
            <div className="sidebar-icon text-secondary border-secondary border-2 w-8 h-8 rounded-full leading-0 overflow-hidden flex items-center text-center justify-between | md:w-8 md:h-8 md:border-2 ">
              <svg
                className="w-full"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 438.536 438.536"
                style={{ padding: '5px' }}
              >
                <path d="M421.981 16.562C410.941 5.519 397.711 0 382.298 0H56.248C40.83 0 27.604 5.521 16.561 16.562 5.52 27.6.001 40.828.001 56.243V382.29c0 15.413 5.518 28.644 16.56 39.683 11.043 11.04 24.272 16.563 39.687 16.563h326.046c15.41 0 28.644-5.523 39.684-16.563 11.043-11.039 16.557-24.27 16.557-39.683V56.243c-.001-15.418-5.514-28.639-16.554-39.681zM157.462 158.025c17.224-16.652 37.924-24.982 62.097-24.982 24.36 0 45.153 8.33 62.381 24.982 17.228 16.655 25.837 36.785 25.837 60.386 0 23.598-8.609 43.729-25.837 60.379-17.228 16.659-38.014 24.988-62.381 24.988-24.172 0-44.87-8.336-62.097-24.988-17.228-16.652-25.841-36.781-25.841-60.379 0-23.601 8.613-43.73 25.841-60.386zm231.403 212.564c0 4.945-1.718 9.083-5.141 12.416-3.433 3.33-7.519 4.996-12.282 4.996h-305.2c-4.948 0-9.091-1.666-12.419-4.996-3.333-3.326-4.998-7.471-4.998-12.416V185.575H89.08c-3.805 11.993-5.708 24.462-5.708 37.402 0 36.553 13.322 67.715 39.969 93.511 26.65 25.786 58.721 38.685 96.217 38.685 24.744 0 47.583-5.903 68.527-17.703 20.937-11.807 37.486-27.839 49.676-48.112 12.183-20.272 18.274-42.4 18.274-66.38 0-12.94-1.91-25.406-5.715-37.402h38.547V370.59h-.002zm0-254.963c0 5.52-1.903 10.184-5.716 13.99-3.805 3.809-8.466 5.711-13.989 5.711h-49.676c-5.517 0-10.185-1.903-13.99-5.711-3.806-3.806-5.708-8.47-5.708-13.99V68.522c0-5.33 1.902-9.945 5.708-13.848 3.806-3.901 8.474-5.854 13.99-5.854h49.676c5.523 0 10.185 1.952 13.989 5.854 3.812 3.903 5.716 8.518 5.716 13.848v47.104z" />
              </svg>
            </div>
          </a>
        </li>

        <li className="sidebar-item z-20 text-white transition-all">
          <a target="_blank" href="https://twitter.com/omb_peezy">
            <div className="sidebar-icon text-secondary border-secondary text-sm border-2 w-8 h-8 rounded-full leading-0 overflow-hidden flex items-center text-center justify-between | md:w-8 md:h-8 md:text-lg md:border-2 ">
              <FontAwesomeIcon className="m-auto" icon={faTwitter as any} />
            </div>
          </a>
        </li>

        <li className="sidebar-item z-20 text-white transition-all">
          <a target="_blank" href="https://open.spotify.com/artist/1QdCkPulANBEZiaiAyLkak">
            <div className="sidebar-icon text-secondary border-secondary text-sm border-2 w-8 h-8 rounded-full leading-0 overflow-hidden flex items-center text-center justify-between | md:w-8 md:h-8 md:text-lg md:border-2 ">
              <FontAwesomeIcon className="m-auto" icon={faSpotify as any} />
            </div>
          </a>
        </li>

        <li className="sidebar-item z-20 text-white transition-all">
          <a target="_blank" href="https://soundcloud.com/ombpeezy">
            <div className="sidebar-icon text-secondary border-secondary text-sm border-2 w-8 h-8 rounded-full leading-0 overflow-hidden flex items-center text-center justify-between | md:w-8 md:h-8 md:text-lg md:border-2 ">
              <FontAwesomeIcon className="m-auto" icon={faSoundcloud as any} />
            </div>
          </a>
        </li>

        <li className="sidebar-item z-20 text-white transition-all">
          <a target="_blank" href="https://www.youtube.com/channel/UCIQuI6sA73cV6AdoeXAKIEQ">
            <div className="sidebar-icon text-secondary border-secondary text-sm border-2 w-8 h-8 rounded-full leading-0 overflow-hidden flex items-center text-center justify-between | md:w-8 md:h-8 md:text-lg md:border-2 ">
              <FontAwesomeIcon className="m-auto" icon={faYoutube as any} />
            </div>
          </a>
        </li>

        <li className="sidebar-item z-20 text-white transition-all">
          <a target="_blank" href="https://www.tiktok.com/@ombpeezy251_?lang=en">
            <div className="sidebar-icon text-secondary border-secondary text-sm border-2 w-8 h-8 rounded-full leading-0 overflow-hidden flex items-center text-center justify-between | md:w-8 md:h-8 md:text-lg md:border-2 ">
              <FontAwesomeIcon className="m-auto" icon={faTiktok as any} />
            </div>
          </a>
        </li>
      </ul>
    </motion.div>
  );
}
