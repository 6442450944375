import { ToursTypes } from 'src/features/Tours/types';

interface TourProps {
  data: ToursTypes[];
}

export default function Tour({ data }: TourProps) {
  const events = data.map((item, idx) =>
    item.past ? null : (
      <div key={idx} className="custom bit-event">
        <a
          className="bit-details"
          href="https://www.bandsintown.com/e/1021426769?affil_code=js_127.0.0.1&app_id=js_127.0.0.1&came_from=242&utm_campaign=event&utm_medium=web&utm_source=widget"
          target="_blank"
          rel="noreferrer"
        >
          <div>
            <span className="bit-date">{item.date}</span>
            <div className="bit-titleWrapper">
              <div className="bit-venue">{item.venue}</div>
            </div>
          </div>
          <div className="bit-location">{item.address}</div>
        </a>
        <div className="bit-event-buttons">
          <div
            className={`bit-rsvp-container ${
              item.vip ? 'opacity-100' : 'opacity-0 hidden md:block'
            } `}
          >
            <a
              className="bit-rsvp bit-button"
              href={item.vip}
              target="_blank"
              rel="noreferrer"
              aria-label="vip"
            >
              VIP
            </a>
          </div>

          <div className="bit-offers-container bit-single-cta">
            <a
              className="bit-offers bit-button"
              target="_blank"
              rel="noreferrer"
              href={item.tickets}
              aria-label="Tickets"
            >
              <span className="bit-offers-text">Tickets</span>
            </a>
          </div>
        </div>
      </div>
    ),
  );

  return <div>{events}</div>;
}
