import { Variants, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const popup: Variants = {
  hidden: {
    opacity: 0,
    marginTop: '-5vh',
  },
  visible: {
    opacity: 1,
    marginTop: '0vh',
  },
};

interface TermsProps {
  visible: boolean;
  set: React.Dispatch<React.SetStateAction<boolean>>;
}

const Terms = ({ visible, set }: TermsProps) => {
  const [zIndex, setzIndex] = useState(false);

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        setzIndex(false);
      }, 500);
    } else {
      setzIndex(visible);
    }
  }, [visible]);

  return (
    <motion.div className={`${visible ? 'block' : 'hidden'} text w-full z-50`}>
      <motion.div
        // animate={visible ? "visible" : "hidden"}
        // initial="hidden"
        // variants={popup}
        className="relative p-4 rounded-md"
      >
        {/* <button
          className="absolute -top-2 -right-2 p-1 w-5 h-5 rounded-full bg-white"
          onClick={() => {
            set(false); // closes the popup
          }}
        >
          <FontAwesomeIcon icon={faX} />
        </button> */}
        By submitting my information, I agree to receive personalized updates and marketing messages
        about OMB Peezy based on my information, interests, activities, website visits and device
        data and in accordance with the
        <a
          target="_blank"
          rel="noreferrer"
          href="https://privacy.wmg.com/3EE/privacy-policy"
          className="text-blue-500 ml-1"
        >
          Privacy Policy
        </a>
        .
        <br />
        <br />I understand that I can opt-out from messages at any time by emailing
        <a href="mailto: privacypolicy@wmg.com" className="text-blue-500 ml-1">
          privacypolicy@wmg.com
        </a>
        .
      </motion.div>
    </motion.div>
  );
};

export default Terms;
