import { RoutePattern } from 'src/routes/RoutePattern';
import OMB1gif from '../../assets/videos/1.gif';
import OMB1mp4 from '../../assets/videos/1.mp4';
import OMB1webm from '../../assets/videos/1.webm';

import OMB2gif from '../../assets/videos/2.gif';
import OMB2mp4 from '../../assets/videos/2.mp4';
import OMB2webm from '../../assets/videos/2.webm';

import OMB3gif from '../../assets/videos/3.gif';
import OMB3mp4 from '../../assets/videos/3.mp4';
import OMB3webm from '../../assets/videos/3.webm';

import OMB4gif from '../../assets/videos/4.gif';
import OMB4mp4 from '../../assets/videos/4.mp4';
import OMB4webm from '../../assets/videos/4.webm';

import OMB5gif from '../../assets/videos/5.gif';
import OMB5mp4 from '../../assets/videos/5.mp4';
import OMB5webm from '../../assets/videos/5.webm';

import OMB6gif from '../../assets/videos/6.gif';
import OMB6mp4 from '../../assets/videos/6.mp4';
import OMB6webm from '../../assets/videos/6.webm';
import {
  faInstagram,
  faSoundcloud,
  faSpotify,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

export interface OMBDataTypes {
  gif: string;
  mp4: string;
  webm: string;
}

export const Data: OMBDataTypes[] = [
  {
    gif: OMB1gif,
    mp4: OMB1mp4,
    webm: OMB1webm,
  },
  {
    gif: OMB2gif,
    mp4: OMB2mp4,
    webm: OMB2webm,
  },
  {
    gif: OMB3gif,
    mp4: OMB3mp4,
    webm: OMB3webm,
  },
  {
    gif: OMB4gif,
    mp4: OMB4mp4,
    webm: OMB4webm,
  },
  {
    gif: OMB5gif,
    mp4: OMB5mp4,
    webm: OMB5webm,
  },
  {
    gif: OMB6gif,
    mp4: OMB6mp4,
    webm: OMB6webm,
  },
];

export interface MenuItemsTypes {
  name: string;
  to?: string;
  href?: string;
}

export const MenuItems: MenuItemsTypes[] = [
  {
    name: 'home',
    to: RoutePattern.Home,
  },
  {
    name: 'shop',
    to: RoutePattern.Shop,
  },
  {
    name: 'music',
    to: RoutePattern.Music,
  },
  {
    name: 'videos',
    to: RoutePattern.Videos,
  },
  {
    name: 'tours',
    href: 'https://www.hightoleranceshop.com/ombpeezytour',
  },
  {
    name: 'contact',
    to: RoutePattern.Contact,
  },
];

export const SocialMediaData = [
  {
    icon: faInstagram,
    to: 'https://www.instagram.com/omb_peezy/',
  },
  {
    icon: faTwitter,
    to: 'https://twitter.com/omb_peezy',
  },
  {
    icon: faSpotify,
    to: 'https://open.spotify.com/artist/1QdCkPulANBEZiaiAyLkak',
  },
  {
    icon: faSoundcloud,
    to: 'https://soundcloud.com/ombpeezy',
  },
  {
    icon: faYoutube,
    to: 'https://www.youtube.com/channel/UCIQuI6sA73cV6AdoeXAKIEQ',
  },
];
